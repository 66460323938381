<template>
  <b-col cols="12">
    <b-card-code>
      <!-- DIV DE PESQUISA -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Procurar</label>
            <b-form-input
              v-model="searchTerm"
              placeholder=" Procurar"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="cadastros"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Máximo por página </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                Total de <b>{{ props.total }}</b> Cadastros
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
        <!-- EDITAVEIS -->
        <template slot="table-row" slot-scope="props">
          <!-- NAME COM LINK -->
          <span v-if="props.column.field === 'name'">
            <router-link :to="`/cadastros/${props.row._id}`">
              {{ props.row.name }}
            </router-link>
          </span>
          <!--STATUS COM BADGE -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status.id)">
              {{ props.row.status.name }}
            </b-badge>
          </span>
        </template>
        <!-- NENHUM CADASTRO -->
        <div slot="emptystate" class="text-center">Nenhum cadastro</div>
      </vue-good-table>
      <!-- ADENDOS -->
      <div>
        <b-row>
          <download-excel
            :data="cadastros"
            :fields="excelField"
            :name="getNameExcel()"
            type="xls"
          >
            <b-button variant="flat-success">
              <img
                src="@/assets/images/excel.png"
                height="70px"
                class="clique"
              />
            </b-button>
          </download-excel>
          <b-button variant="flat-success" v-b-modal.global>
            <feather-icon icon="RadioIcon" size="30" />
          </b-button>
        </b-row>
      </div>
    </b-card-code>
    <b-modal
      id="global"
      hide-footer
      size="lg"
      centered
      :no-close-on-backdrop="modalClosed"
      :no-close-on-esc="modalClosed"
      :hide-header-close="modalClosed"
    >
      <Global v-if="!enviando" :enviar="enviar" />
      <div class="d-flex justify-content-center mb-1" v-else>
        <b-spinner
          label="Loading..."
          style="width: 3rem; height: 3rem"
          variant="primary"
        />
        <h4 class="m-1">enviando ...</h4>
      </div>
    </b-modal>
  </b-col>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import store from "@/store/index";
import Global from "./Global.vue";
import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
  components: {
    VueGoodTable,
    BCardCode,
    Global,
  },
  props: {
    cadastros: {
      type: Array,
    },
  },
  data() {
    return {
      modalClosed: false,
      enviando: false,
      enviado: false,
      searchTerm: "",
      pageLength: 10,
      columns: [
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Equipe",
          field: "teamsize",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      excelField: {
        "Status": "status.name",
        "Nome Fantasia": "name",
        "Razão Social":"corporatename",
        "Equipe": "teamsize",
        "CNPJ": "cnpj",
        "Email": "email",
        "CRECI-PJ": "crecipj",
        "Proprietário": "owner.name",
        "CPF-proprietário": "owner.cpf",
        "CRECI-proprietário": "owner.creci",
        "Email-proprietário": "owner.email",
        "Telefone-proprietário": "owner.phone",
      },
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
    statusVariant(status) {
      const statusColor = {
        0: "danger",
        1: "light-warning",
        2: "light-info",
        3: "primary",
      };
      return (status) => statusColor[status];
    },
  },
  methods: {
    getNameExcel() {
      let now = new Date();
      return `Imobiliarias_cadastradas_${now.getDate()}-${
        now.getMonth() + 1
      }-${now.getFullYear()}.xls`;
    },
    async enviar(contatos, msg) {
      this.enviando = true;
      this.modalClosed = true;
      const content = {
        contatos,
        msg,
      };

      await axios.post(`${baseApiUrl}/wp/sendbulk`, content).then((res) => {
        if (res.data.success) {
          this.enviando = false;
          this.modalClosed = false;
          this.$bvModal.hide("global");
        }
      });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
.clique:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.5s;
}
</style>