<template>
  <div>
    <!------------- 
      PAINEL GERAL
      ------------->
    <b-col cols="12">
      <b-card no-body class="mb-1">
        <b-card-header class="p-1">
          <b-button block v-b-toggle.geral variant="qualquernome"
            ><h4>GERAL [ {{cadastros.length}} ]</h4>
          </b-button>
        </b-card-header>
        <b-collapse visible id="geral">
          <b-card-body>
            <b-row>
              <!-- CARD DESCARTADO-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media no-body class="panel" @click="setGeralFilter(0)">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="danger">
                      {{ calcTotalPercent(0) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setGeral(0) }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      DESCARTADO
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD GERAL-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media no-body class="panel" @click="setGeralFilter(1)">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="warning">
                      {{ calcTotalPercent(1) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setGeral(1) }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      ANÁLISE
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD APROVAÇÃO-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media no-body class="panel" @click="setGeralFilter(2)">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcTotalPercent(2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ setGeral(2) }}</h4>
                    <b-card-text class="font-small-3 mb-0"> AUSA </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD CREDENCIADOS-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media no-body class="panel" @click="setGeralFilter(3)">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="primary">
                      {{ calcTotalPercent(3) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ setGeral(3) }}</h4>
                    <b-card-text class="font-small-3 mb-0">
                      CREDENCIADOS
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-col>

    <!-------------- 
      PAINEL ANÁLISE
      -------------->
    <b-col cols="12">
      <b-card no-body class="mb-1">
        <b-card-header class="p-1">
          <b-button block v-b-toggle.analise variant="qualquernome"
            ><h4>ANÁLISE [{{ calcTotalPercent(1) }}%]</h4>
          </b-button>
        </b-card-header>
        <b-collapse id="analise">
          <b-card-body>
            <b-row>
              <!-- CARD ANÁLISE-->
              <b-col xl="2" sm="3" class="p-2 m-1">
                <b-media no-body class="panel" @click="setFilter('Análise')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="warning">
                      {{ calcPercent("Análise", 1) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Análise") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      ANÁLISE
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD PENDÊNCIA-->
              <b-col xl="2" sm="3" class="p-2 m-1">
                <b-media no-body class="panel" @click="setFilter('Pendência')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="warning">
                      {{ calcPercent("Pendência", 1) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Pendência") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      PENDÊNCIA
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD LIBERADO-->
              <b-col xl="2" sm="3" class="p-2 m-1">
                <b-media no-body class="panel" @click="setFilter('Liberado')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="warning">
                      {{ calcPercent("Liberado", 1) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Liberado") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      LIBERADO
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD CREDENCIADOS-->
              <b-col xl="2" sm="3" class="p-2 m-1">
                <b-media no-body class="panel" @click="setFilter('Agendado')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="warning">
                      {{ calcPercent("Agendado", 1) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Agendado") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      AGENDADO
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD SOS-->
              <b-col xl="2" sm="3" class="p-2 m-1">
                <b-media no-body class="panel" @click="setFilter('SOS')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-warning">
                      {{ calcPercent("SOS", 1) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("SOS") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0"> STANDBY </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse></b-card
      >
    </b-col>

    <!------------ 
      PAINEL AUSA
      ------------>
    <b-col cols="12">
      <b-card
        no-body
        class="card-statistics"
        title="AUSA"
        action-collapse
        :collapsed="true"
      >
        <b-card-header class="p-1">
          <b-button block v-b-toggle.ausa variant="qualquenome"
            ><h4>AUSA [{{ calcTotalPercent(2) }}%]</h4>
          </b-button>
        </b-card-header>
        <b-collapse id="ausa">
          <b-card-body>
            <b-row>
              <!-- CARD ANÁLISE-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media no-body class="panel" @click="setFilter('LGPD')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcPercent("LGPD", 2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("LGPD") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0"> LGPD </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD PENDÊNCIA-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media
                  no-body
                  class="panel"
                  @click="setFilter('Fornecedor Pendente')"
                >
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcPercent("Fornecedor Pendente", 2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Fornecedor Pendente") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      FORNECEDOR PENDENTE
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD LIBERADO-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media no-body class="panel" @click="setFilter('Cadastro')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcPercent("Cadastro", 2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Cadastro") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      CADASTRO
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD CREDENCIADOS-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media
                  no-body
                  class="panel"
                  @click="setFilter('Rejeitado Cadastro')"
                >
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcPercent("Rejeitado Cadastro", 2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Rejeitado Cadastro") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      REJEITADO CADASTRO
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD CREDENCIADOS-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media no-body class="panel" @click="setFilter('Compliance')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcPercent("Compliance", 2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Compliance") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      COMPLIANCE
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD CREDENCIADOS-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media
                  no-body
                  class="panel"
                  @click="setFilter('Rejeitado Compliance')"
                >
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcPercent("Rejeitado Compliance", 2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Rejeitado Compliance") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      REJEITADO COMPLIANCE
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD Fiscal-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media no-body class="panel" @click="setFilter('Fiscal')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcPercent("Fiscal", 2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Fiscal") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      FISCAL
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <!-- CARD Rejeitado Fiscal-->
              <b-col xl="3" sm="6" class="p-2">
                <b-media
                  no-body
                  class="panel"
                  @click="setFilter('Rejeitado Fiscal')"
                >
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      {{ calcPercent("Rejeitado Fiscal", 2) }}%
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ setPanel("Rejeitado Fiscal") }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      REJEITADO FISCAL
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    cadastros: {
      type: Array,
    },
  },
  methods: {
    setFilter(filtro) {
      let lista = this.cadastros.filter((cadastro) => {
        return cadastro.status.name === filtro;
      });
      if (filtro === "") {
        this.$emit("setList", this.cadastros);
      } else {
        this.$emit("setList", lista);
      }
    },
    setGeralFilter(filtro) {
      let lista = this.cadastros.filter((cadastro) => {
        return cadastro.status.id === filtro;
      });
      this.$emit("setList", lista);
    },
    setPanel(stats) {
      let count = 0;
      this.cadastros.filter((cadastro) => {
        if (cadastro.status.name === stats) {
          count++;
        }
      });
      return count;
    },
    calcPercent(status, id = null) {
      let total = this.cadastros.length;
      if (id) {
        total = this.cadastros.filter((cadastro) => {
          return cadastro.status.id === id;
        }).length;
      }
      let count = 0;
      this.cadastros.filter((cadastro) => {
        if (cadastro.status.name === status) {
          count++;
        }
      });
      if (count === 0) return count;
      return (count * 100) / total;
    },
    setGeral(status) {
      let count = 0;
      this.cadastros.filter((cadastro) => {
        if (cadastro.status.id === status) {
          count++;
        }
      });
      return count;
    },
    calcTotalPercent(status) {
      let total = this.cadastros.length;
      let count = 0;
      this.cadastros.filter((cadastro) => {
        if (cadastro.status.id === status) {
          count++;
        }
      });
      if (count === 0) return count;
      return Math.floor((count * 100) / total);
    },
  },
};
</script>

<style>
.panel:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.5s;
}
</style>