<template>
  <b-row>
    <b-col cols="12">
      <form-wizard color="#428517" title="MENSAGENS" subtitle="Enviar Whatsapp">
        <!-- Botões de ações -->
        <div slot="footer" slot-scope="props" class="text-right">
          <b-button
            @click="props.prevTab()"
            v-if="props.activeTabIndex > 0"
            class="m-1"
            variant="warning"
            >Voltar</b-button
          >
          <b-button
            @click="props.nextTab()"
            variant="primary"
            v-if="!props.isLastStep"
            >Avançar</b-button
          >
          <b-button
            v-else
            variant="primary"
            v-b-modal.recaptcha
            @click="confirmBox()"
            >Finalizar</b-button
          >
        </div>
        <!-- GRUPO -->
        <tab-content title="GRUPO" class="mb-2" :before-change="firstStep">
          <b-alert variant="danger" show v-if="showAlert"
            >Selecione ao menos um grupo de destinatário</b-alert
          >
          <b-row>
            <b-col cols="3"> </b-col>
            <b-col cols="9">
              <validation-observer ref="msgRules" tag="form">
                <b-form-group label="">
                  <validation-provider name="corporatename" rules="required">
                    <!--IMOBILIÁRIAS -->
                    <div class="m-2">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="imobSwitch"
                      >
                        <h2>Imobiliárias</h2>
                      </b-form-checkbox>
                    </div>
                    <!-- AUSA -->
                    <div class="m-2">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="ausaSwitch"
                      >
                        <h2>AUSA</h2>
                      </b-form-checkbox>
                      <div v-if="ausaSwitch" class="m-1">
                        <b-form-checkbox
                          v-for="option in ausaOptions"
                          :key="option.value"
                          v-model="ausaSelected"
                          :value="option.value"
                          name="flavour-4a"
                          class="mt-1"
                        >
                          {{ option.text }}
                        </b-form-checkbox>
                      </div>
                    </div>
                    <!--ANÁLISE -->
                    <div class="m-2">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="analiseSwitch"
                      >
                        <h2>Análise</h2>
                      </b-form-checkbox>
                      <div v-if="analiseSwitch" class="m-1">
                        <b-form-checkbox
                          v-for="option in analiseOptions"
                          :key="option.value"
                          v-model="analiseSelected"
                          :value="option.value"
                          name="flavour-4a"
                          class="mt-1"
                        >
                          {{ option.text }}
                        </b-form-checkbox>
                      </div>
                    </div>
                    <!--Descartados -->
                    <div class="m-2">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="descSwitch"
                      >
                        <h2>Descartados</h2>
                      </b-form-checkbox>
                    </div>
                  </validation-provider>
                </b-form-group>
              </validation-observer>
            </b-col>
          </b-row>
        </tab-content>

        <!-- MENSAGEM -->
        <tab-content title="Mensagem" class="mb-2">
          <b-form-group
            label="Arquivo:"
            label-for="file-large"
            label-cols-sm="2"
            label-size="lg"
          >
            <b-form-file
              id="file-large"
              size="lg"
              placeholder="Procurar arquivo"
              v-model="files"
            />
          </b-form-group>
          <div>
            <label for="textarea-default">Mensagem</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Escreva aqui a mensagem..."
              rows="3"
              v-model="mensagem.content"
            />
          </div>
        </tab-content>
      </form-wizard>
    </b-col>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "vee-validate/dist/rules.umd.js";
extend("required", required);
extend("min", min);
extend("email", email);

import axios from "axios";
import { baseApiUrl } from "@/global";
import { mapState } from "vuex";

export default {
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    enviar: Function,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      imobs: [],
      cadastros: [],
      showAlert: false,
      imobSwitch: false,
      descSwitch: false,
      ausaSwitch: false,
      analiseSwitch: false,
      analiseSelected: [],
      analiseOptions: [
        { text: "Em Análise", value: "Análise" },
        { text: "Pendência", value: "Pendência" },
        { text: "Liberados", value: "Liberado" },
        { text: "Agendados", value: "Agendado" },
        { text: "SOS", value: "SOS" },
      ],
      ausaSelected: [],
      ausaOptions: [
        { text: "LGPD", value: "LGPD" },
        { text: "Fornecedor Pendente", value: "Fornecedor Pendente" },
        { text: "Cadastro", value: "Cadastro" },
        { text: "Rejeitado Cadastro", value: "Rejeitado Cadastro" },
        { text: "Complience", value: "Complience" },
        { text: "Rejeitado Complience", value: "Rejeitado Complience" },
        { text: "Fiscal", value: "Fiscal" },
        { text: "Rejeitado Fiscal", value: "Rejeitado Fiscal" },
      ],
      selected: [],
      arquivo: null,
      mensagem: {
        content: "",
        files: {},
      },
      files: null,
    };
  },
  methods: {
    firstStep() {
      return new Promise((resolve, reject) => {
        if (
          !this.imobSwitch &&
          !this.analiseSwitch &&
          !this.ausaSwitch &&
          !this.descSwitch
        ) {
          this.showAlert = true;
          resolve(false);
          reject();
        } else if (this.analiseSwitch && !this.analiseSelected.length) {
          this.showAlert = true;
          resolve(false);
          reject();
        } else if (this.ausaSwitch && !this.ausaSelected.length) {
          this.showAlert = true;
          resolve(false);
          reject();
        } else {
          this.showAlert = false;
          resolve(true);
        }
      });
    },
    confirmBox() {
      if (!this.mensagem && !this.arquivo) {
        this.$bvToast.toast("Você deve ao menos escrever uma mensagem...", {
          title: "Hey !",
          variant: "danger",
          solid: true,
        });
        return;
      }
      this.$bvModal
        .msgBoxConfirm(`Pode enviar mesmo?`, {
          title: "Confirmar",
          okTitle: "Sim",
          cancelTitle: "Não",
        })
        .then((res) => {
          if (res) {
            this.sendMessage();
          }
        });
    },
    async sendMessage() {
      //PEGA AS IMOBS
      if (this.imobSwitch) {
        await this.getCadastros(3, "Credenciado");
      }
      //PEGA AS ANALISES
      if (this.analiseSwitch) {
        for (let i = 0; this.analiseSelected.length > i; i++) {
          await this.getCadastros(1, this.analiseSelected[i]);
        }
      }
      // PEGA ANALISE AUSA
      if (this.ausaSwitch) {
        for (let i = 0; this.ausaSelected.length > i; i++) {
          await this.getCadastros(2, this.ausaSelected[i]);
        }
      }
      // PEGA DESCARTADOS
      if (this.descSwitch) {
        await this.getCadastros(0, "Descartado");
      }
      //Checa msg vazia
      if (this.mensagem.content === "" && !this.files) {
        this.$bvToast.toast("Escreva uma mensagem ao menos", {
          title: "Error !",
          variant: "danger",
          solid: true,
        });
        return;
      }
      //Retorna erro se não tiver cadastros
      if (this.cadastros.length === 0) {
        //Erro caso não tenha imob cadastrada
        this.$bvToast.toast("Lista não há nenhum contato registrado", {
          title: "Error !",
          variant: "danger",
          solid: true,
        });
        return;
      }
      //Checa se tem files
      let mensagem = { ...this.mensagem };
      if (this.files) {
        let base64data;
        var reader = new FileReader();
        console.log(this.files);
        reader.readAsDataURL(this.files);
        mensagem.files.name = this.files.name;
        mensagem.files.type = this.files.type;

        await new Promise((resolve, reject) => {
          reader.onload = async function () {
            base64data = reader.result.replace("data:", "").replace(/^.+,/, "");
            mensagem.files.base64data = base64data;
            resolve();
          };
          reader.onerror = function () {
            reject(reader.error);
          };
        });
      }
      console.log("done");
      //Passa os dados para enviar
      this.enviar(this.cadastros, this.mensagem);

      //RESETA
      this.cadastros = [];
      this.imobs = [];
      this.ausaSelected = [];
      this.analiseSelected = [];
      this.imobSwitc = false;
      this.descSwitch = false;
      this.ausaSwitch = false;
      this.analiseSwitch = false;
    },
    async getCadastros(id, name = "") {
      let filter;
      if (name === "") {
        filter = null;
      } else {
        filter = {
          id,
          name,
        };
      }
      await axios
        .post(`${baseApiUrl}/cadastros`, {
          db: this.user.user.produto.db,
          filter,
        })
        .then((res) => {
          if (res.data.success) {
            this.cadastros = this.cadastros.concat(res.data.data);
          }
        });
    },
  },
  watch: {
    ausaSwitch(ausa) {
      if (ausa) {
        for (let i = 0; i < this.ausaOptions.length; i++)
          this.ausaSelected.push(this.ausaOptions[i].value);
      } else {
        this.ausaSelected = [];
      }
    },
    analiseSwitch(analise) {
      if (analise) {
        for (let i = 0; i < this.analiseOptions.length; i++)
          this.analiseSelected.push(this.analiseOptions[i].value);
      } else {
        this.analiseSelected = [];
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Buscar";
}
.align {
  background-color: red;
  right: 100px;
}
</style>
 