<template>
  <div>
    <div v-if="!havData" class="text-center">
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <Panel :cadastros="cadastros" @setList="setList" />
      <List  :cadastros="listaCadastro" />
    </div>
  </div>
</template>

<script>
import Panel from "./components/Panel.vue";
import List from "./components/List.vue";

import axios from "axios";
import { baseApiUrl } from "@/global";
import { mapState } from "vuex";

export default {
  components: {
    Panel,
    List,
  },
  data() {
    return {
      havData: false,
      cadastros: [],
      listaCadastro: [],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async getCadastros(filter = "") {
      await axios
        .post(`${baseApiUrl}/cadastros`, {
          db: this.user.user.produto.db,
          filter,
        })
        .then((res) => {
          if (res.data.success) {
            this.cadastros = res.data.data;
            this.setList(res.data.data);
            this.fixStatus(res.data.data)
          }
        });
    },
    setList(cadastros) {
      this.listaCadastro = cadastros;
      this.havData = true
    },

    //temp
    fixStatus(data) {
      for (let i = 0; i < data.length; i++)

      if(data[i].status === "Análise") {
        data[i].status = {
          id: 1,
          name: data[i].status
        }

        axios.put(`${baseApiUrl}/cadastros`, {
          db: this.user.user.produto.db,
          imob: data[i]
        }).then(res => {
          console.log(res)
        })
      }
      
    }
  },
  mounted() {
    this.getCadastros();
  },
};
</script>

<style>
</style>